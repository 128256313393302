import { convertToCleanedName } from "./helpers";

export const provincesAndCities = [
  {
    city: "Phnom Penh",
    province: "Campuchia",
  },
  {
    city: "Nairobi",
    province: "Kenya",
  },
  {
    city: "Mumbai",
    province: "Ấn Độ",
  },
  {
    city: "An Giang",
    province: "An Giang",
    distanceToHCMC: 232,
    distanceToHN: 1890,
  },
  {
    city: "Vũng Tàu",
    province: "Vũng Tàu",
    distanceToHCMC: 125,
    distanceToHN: 1749,
  },
  {
    city: "Bạc Liêu",
    province: "Bạc Liêu",
    distanceToHCMC: 291,
    distanceToHN: 2063,
  },
  {
    city: "Bắc Giang",
    province: "Bắc Giang",
    distanceToHCMC: 1787,
    distanceToHN: 50,
  },
  {
    city: "Bắc Kạn",
    province: "Bắc Kạn",
    distanceToHCMC: 1693,
    distanceToHN: 162,
  },
  {
    city: "Bắc Ninh",
    province: "Bắc Ninh",
    distanceToHCMC: 1665,
    distanceToHN: 31,
  },
  {
    city: "Bến Tre",
    province: "Bến Tre",
    distanceToHCMC: 87,
    distanceToHN: 1764,
  },
  {
    city: "Bình Định",
    province: "Bình Định",
    distanceToHCMC: 649,
    distanceToHN: 1074,
  },
  {
    city: "Bình Dương",
    province: "Bình Dương",
    distanceToHCMC: 27,
    distanceToHN: 1660,
  },
  {
    city: "Bình Phước",
    province: "Bình Phước",
    distanceToHCMC: 116,
    distanceToHN: 1785,
  },
  {
    city: "Bình Thuận",
    province: "Bình Thuận",
    distanceToHCMC: 198,
    distanceToHN: 1545,
  },
  {
    city: "Cà Mau",
    province: "Cà Mau",
    distanceToHCMC: 307,
    distanceToHN: 2223,
  },
  {
    city: "Cần Thơ",
    province: "Cần Thơ",
    distanceToHCMC: 169,
    distanceToHN: 1892,
  },
  {
    city: "Cao Bằng",
    province: "Cao Bằng",
    distanceToHCMC: 1572,
    distanceToHN: 281,
  },
  {
    city: "Đà Nẵng",
    province: "Đà Nẵng",
    distanceToHCMC: 767,
    distanceToHN: 767,
  },
  {
    city: "Đắk Lắk",
    province: "Đắk Lắk",
    distanceToHCMC: 402,
    distanceToHN: 1327,
  },
  {
    city: "Đắk Nông",
    province: "Đắk Nông",
    distanceToHCMC: 366,
    distanceToHN: 1474,
  },
  {
    city: "Điện Biên",
    province: "Điện Biên",
    distanceToHCMC: 1999,
    distanceToHN: 474,
  },
  {
    city: "Đồng Nai",
    province: "Đồng Nai",
    distanceToHCMC: 39,
    distanceToHN: 1662,
  },
  {
    city: "Đồng Tháp",
    province: "Đồng Tháp",
    distanceToHCMC: 139,
    distanceToHN: 1881,
  },
  {
    city: "Gia Lai",
    province: "Gia Lai",
    distanceToHCMC: 521,
    distanceToHN: 1080,
  },
  {
    city: "Hà Giang",
    province: "Hà Giang",
    distanceToHCMC: 1970,
    distanceToHN: 320,
  },
  {
    city: "Hà Nam",
    province: "Hà Nam",
    distanceToHCMC: 1613,
    distanceToHN: 63,
  },
  {
    city: "Hà Nội",
    province: "Hà Nội",
    distanceToHCMC: 1680,
    distanceToHN: 0,
  },
  {
    city: "Hà Tĩnh",
    province: "Hà Tĩnh",
    distanceToHCMC: 545,
    distanceToHN: 340,
  },
  {
    city: "Hải Dương",
    province: "Hải Dương",
    distanceToHCMC: 1700,
    distanceToHN: 72,
  },
  {
    city: "Hải Phòng",
    province: "Hải Phòng",
    distanceToHCMC: 1680,
    distanceToHN: 120,
  },
  {
    city: "Hậu Giang",
    province: "Hậu Giang",
    distanceToHCMC: 225,
    distanceToHN: 1947,
  },
  {
    city: "Hòa Bình",
    province: "Hòa Bình",
    distanceToHCMC: 1350,
    distanceToHN: 76,
  },
  {
    city: "Hưng Yên",
    province: "Hưng Yên",
    distanceToHCMC: 1640,
    distanceToHN: 40,
  },
  {
    city: "Khánh Hòa",
    province: "Khánh Hòa",
    distanceToHCMC: 448,
    distanceToHN: 1278,
  },
  {
    city: "Kiên Giang",
    province: "Kiên Giang",
    distanceToHCMC: 295,
    distanceToHN: 1964,
  },
  {
    city: "Kon Tum",
    province: "Kon Tum",
    distanceToHCMC: 522,
    distanceToHN: 1164,
  },
  {
    city: "Lai Châu",
    province: "Lai Châu",
    distanceToHCMC: 1860,
    distanceToHN: 442,
  },
  {
    city: "Lâm Đồng",
    province: "Lâm Đồng",
    distanceToHCMC: 308,
    distanceToHN: 1487,
  },
  {
    city: "Lạng Sơn",
    province: "Lạng Sơn",
    distanceToHCMC: 1824,
    distanceToHN: 158,
  },
  {
    city: "Lào Cai",
    province: "Lào Cai",
    distanceToHCMC: 1964,
    distanceToHN: 279,
  },
  {
    city: "Long An",
    province: "Long An",
    distanceToHCMC: 47,
    distanceToHN: 1708,
  },
  {
    city: "Nam Định",
    province: "Nam Định",
    distanceToHCMC: 1620,
    distanceToHN: 87,
  },
  {
    city: "Nghệ An",
    province: "Nghệ An",
    distanceToHCMC: 1526,
    distanceToHN: 370,
  },
  {
    city: "Ninh Bình",
    province: "Ninh Bình",
    distanceToHCMC: 1583,
    distanceToHN: 95,
  },
  {
    city: "Ninh Thuận",
    province: "Ninh Thuận",
    distanceToHCMC: 350,
    distanceToHN: 1371,
  },
  {
    city: "Phú Thọ",
    province: "Phú Thọ",
    distanceToHCMC: 1750,
    distanceToHN: 80,
  },
  {
    city: "Phú Yên",
    province: "Phú Yên",
    distanceToHCMC: 560,
    distanceToHN: 1094,
  },
  {
    city: "Quảng Bình",
    province: "Quảng Bình",
    distanceToHCMC: 522,
    distanceToHN: 492,
  },
  {
    city: "Quảng Nam",
    province: "Quảng Nam",
    distanceToHCMC: 800,
    distanceToHN: 777,
  },
  {
    city: "Quảng Ngãi",
    province: "Quảng Ngãi",
    distanceToHCMC: 682,
    distanceToHN: 832,
  },
  {
    city: "Quảng Ninh",
    province: "Quảng Ninh",
    distanceToHCMC: 1700,
    distanceToHN: 150,
  },
  {
    city: "Quảng Trị",
    province: "Quảng Trị",
    distanceToHCMC: 1120,
    distanceToHN: 598,
  },
  {
    city: "Sóc Trăng",
    province: "Sóc Trăng",
    distanceToHCMC: 231,
    distanceToHN: 2080,
  },
  {
    city: "Sơn La",
    province: "Sơn La",
    distanceToHCMC: 2024,
    distanceToHN: 265,
  },
  {
    city: "Tây Ninh",
    province: "Tây Ninh",
    distanceToHCMC: 99,
    distanceToHN: 1743,
  },
  {
    city: "Thái Bình",
    province: "Thái Bình",
    distanceToHCMC: 1711,
    distanceToHN: 110,
  },
  {
    city: "Thái Nguyên",
    province: "Thái Nguyên",
    distanceToHCMC: 1782,
    distanceToHN: 75,
  },
  {
    city: "Thanh Hóa",
    province: "Thanh Hóa",
    distanceToHCMC: 1666,
    distanceToHN: 140,
  },
  {
    city: "Thừa Thiên Huế",
    province: "Thừa Thiên Huế",
    distanceToHCMC: 1044,
    distanceToHN: 654,
  },
  {
    city: "Tiền Giang",
    province: "Tiền Giang",
    distanceToHCMC: 70,
    distanceToHN: 1911,
  },
  {
    city: "Hồ Chí Minh",
    province: "Hồ Chí Minh",
    distanceToHCMC: 0,
    distanceToHN: 1680,
  },
  {
    city: "Trà Vinh",
    province: "Trà Vinh",
    distanceToHCMC: 149,
    distanceToHN: 1875,
  },
  {
    city: "Tuyên Quang",
    province: "Tuyên Quang",
    distanceToHCMC: 1744,
    distanceToHN: 140,
  },
  {
    city: "Vĩnh Long",
    province: "Vĩnh Long",
    distanceToHCMC: 136,
    distanceToHN: 1930,
  },
  {
    city: "Vĩnh Phúc",
    province: "Vĩnh Phúc",
    distanceToHCMC: 1703,
    distanceToHN: 66,
  },
  {
    city: "Yên Bái",
    province: "Yên Bái",
    distanceToHCMC: 1962,
    distanceToHN: 183,
  },
];

export const provincesAndCitiesObj = provincesAndCities.reduce((acc, { province }) => {
  const cleanedName = convertToCleanedName(province);
  acc[cleanedName] = province;
  return acc;
}, {});
